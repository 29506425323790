<template>
  <div class="img-wrapper lazyLoad">
<!--     <h2>全媒体运营经理</h2>-->
    <div v-for="img in imgList">
       <img v-lazy="img.url" class="img-item" >
    </div>
  </div>
</template>
<script>
export  default {
  data(){
    return{
      imgList:[{
        url:require("../../../../static/images/media/1.jpg"),
      },{
         url:require("../../../../static/images/media/2.jpg"),
      },{
         url:require("../../../../static/images/media/3.jpg"),
      },{
         url:require("../../../../static/images/media/4.jpg"),
      },{
         url:require("../../../../static/images/media/5.jpg"),
      },{
         url:require("../../../../static/images/media/6.jpg"),
      },{
         url:require("../../../../static/images/media/8.jpg"),
      },{
         url:require("../../../../static/images/media/11.jpg"),
      },{
         url:require("../../../../static/images/media/12.jpg"),
      },{
         url:require("../../../../static/images/media/13.jpg"),
      },]
    }
  }
}
</script>
<style lang="less">
  .img-wrapper{
    width: 100%;
    .img-item{
      width: 100%;
      height: 100%;
    }
  }
  img[lazy="loading"]{
  display:block;
  //width:50px !important;
  //height:50px !important;
  margin:0 auto;
  }
</style>
